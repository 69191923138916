var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isCurrentPage && _vm.isLoaded)?_c('div',{staticClass:"student-footer"},[_c('div',{staticClass:"footer d-flex text-light text-left footer-space"},[_c('div',{staticClass:"col-4",style:({
      opacity:
        _vm.isExplorer
          ? '100%'
          : '20%',
    })},[_vm._m(0),(_vm.isZealandFlow)?_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3)]):_c('ul',[_vm._m(4),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"col-4",style:({ opacity: _vm.isRisingStar ? '100%' : '20%' })},[_vm._m(7),(_vm.isZealandFlow)?_c('ul',[_vm._m(8),_vm._m(9),_vm._m(10)]):_c('ul',[_vm._m(11),_vm._m(12),_vm._m(13)])]),_c('div',{staticClass:"col-4",style:({ opacity: !_vm.isExplorer && !_vm.isRisingStar ? '100%' : '20%' })},[_vm._m(14),(_vm.isZealandFlow)?_c('ul',[_vm._m(15),_vm._m(16),_vm._m(17)]):_c('ul',[_vm._m(18),_vm._m(19),_vm._m(20)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px","margin-bottom":"10px"}},[_c('img',{staticClass:"icon_img",attrs:{"src":require("@/assets/search-big.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"mb-0 text-uppercase footer-header"},[_vm._v("Explorer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Brug Excelerate til Zealand events ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Ansøg til praktik- og jobstillinger ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Udforsk virksomheder og karrieremuligheder ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Search thesis inspiration ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Explore student thesis topics ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Professional guidance on thesis project ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px","margin-bottom":"10px"}},[_c('img',{staticClass:"icon_img",attrs:{"src":require("@/assets/arrow-big.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"mb-0 text-uppercase footer-header"},[_vm._v("Rising Star")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Deltag i karriereevents kun for Exceleraters ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Udforsk virksomheder til projektsamarbejder ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Få sparring fra en Excelerate karriereekspert ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Unlock Excelerate Thesis Collaboration Matching ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Match with companies in your industry ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Upload thesis topic / project proposal ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px","margin-bottom":"10px"}},[_c('img',{staticClass:"icon_img",attrs:{"src":require("@/assets/star-big.png"),"alt":"","srcset":""}}),_c('p',{staticClass:"mb-0 text-uppercase footer-header"},[_vm._v("Talent")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" 3x dine praktik- og jobmatching chancer ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Skil dig ud fra mængden med en Talent profil ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Adgang til Excelerate Karriere Matching ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Unlock Excelerate Career Matching ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Triple thesis collaboration chances ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa fa-check",attrs:{"aria-hidden":"true"}}),_vm._v(" Boost profile to stand out ")])
}]

export { render, staticRenderFns }